<template>
<div class="container-fluid">
  <!-- Content here -->

<div class="row" style="padding-top:6px">
    <div class="col-lg-4 col-md-4">
      <img width="336" src="/cropped-ic-moe-logo.png">
    </div>
    <div class="col-lg-4 col-md-4">
      <img  height="102" src="/jakra.png">
    </div>
    <div class="col-lg-4 col-md-4">
      <img width="336" src="/aqcs_logo.png" style="display: none;">
    </div>
   
  </div>
 
  <nav class="navbar navbar-expand-lg navbar-light bg-light" style="background-color: #077f36;">
    <div class="container-fluid">
     <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
            <router-link tag="a" class="nav-item nav-link" to="/home">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link tag="a" class="nav-item nav-link" to="/about">About Air Quality</router-link>
        </li>
        <li class="nav-item">
          <a href="http://www.moe.gov.kh/" class="nav-item nav-link"  >Ministry of Environment </a> </li>
      </ul>
    </div>
    </div>
  </nav>
 

</div>
</template>


<script>
export default {
  name: 'Header',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.router-link-active{
  color: green;
}
</style>
