<template>
<footer class="footer">
        <div class="container" style="padding:10px 100px 10px 100px; color: #fff;">
            <div class="row">

                <div class="col-md-12 col-ms-12">
                    <div class="widget-footer widget-footer-01">
                        <h2 style="font-size: 15px;">ទំនាក់ទំនងក្រសួង</h2>
                        <div style="font-size: 13px;">
                            <ul style="list-style-type: none; color: #077f36;">
                                <li>
                                    អគារមរតកតេជោ ដីឡូលេខ៥០៣ ផ្លូវកៅស៊ូអមមាត់ទន្លេបាសាក់<br>សង្កាត់ទន្លេបាសាក់ ខណ្ឌចំការមន រាជធានីភ្នំពេញ
                                </li>
                                <li>
                                    (+855) 23 213 908<br>(+855) 23 220 369
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>



            </div>
            <div class="row">
                <div class="col-12">
                    <div style="text-align: center; font-size: 11px; color: #077f36;">
                        <p>&copy; ឆ្នាំ២០១៧ រក្សាសិទ្ធិគ្រប់យ៉ាងដោយ៖ ក្រសួងបរិស្ថាន</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>


<script>
export default {
  name: 'Header',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.footer {
            background-color: #fff/*#077f36*/;
            color: #077f36;
        }
</style>